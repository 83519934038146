import React, { useEffect, useState } from 'react';
import qs from 'qs';
import SetPassword from 'features/SetPassword';
import { useLazyQuery, useMutation } from '@apollo/client';
import SEO from 'components/seo';
import {
  DECRYPT_EMAIL,
  CREATE_VERIFIED_ACCOUNT,
  CHECK_USER_EXISTS_AUTH0,
} from 'services/graphql/queries/profile';
import Spinner from 'components/Spinner';
import { navigate } from 'gatsby';
import ModalWithButtons from 'components/Modal/ModalWithButtons';
import { useAuth0 } from '@auth0/auth0-react';

const Password = ({ location }) => {
  const [email, setEmail] = useState('');
  const [salesforceToken, setSalesforceToken] = useState('');
  const [showModal, setShowModal] = useState({
    show: false,
    title: '',
    buttons: [],
    bodyText: '',
  });
  const search = location.search?.substring(1);
  const { logout, isAuthenticated, isLoading } = useAuth0();
  const id = qs.parse(search)?.id;
  const [willLogOut, setWillLogOut] = useState(false);
  const [
    decryptEmail,
    {
      error: decryptEmailError,
      data: decryptEmaildata,
      loading: decryptEmailLoading,
    },
  ] = useMutation(DECRYPT_EMAIL);

  const [
    checkUser,
    { loading: checkUserLoading, data: checkUserData },
  ] = useLazyQuery(CHECK_USER_EXISTS_AUTH0);

  const [
    createVerifiedAccount,
    {
      error: createAccountError,
      data: createAccountData,
      loading: createAccountLoading,
    },
  ] = useMutation(CREATE_VERIFIED_ACCOUNT);

  useEffect(() => {
    if (!isLoading) {
      if (!isAuthenticated)
        decryptEmail({
          variables: {
            encryptedHex: id,
          },
        });
      else {
        setWillLogOut(true);
        //log user out to prevent infinte redirections between here and the login screen if the user was redirected there
        return logout({ returnTo: window.location.href });
      }
    }
  }, [isAuthenticated, isLoading]);

  useEffect(() => {
    if (decryptEmaildata?.DecryptEmail) {
      setEmail(decryptEmaildata.DecryptEmail.email);
      setSalesforceToken(decryptEmaildata.DecryptEmail.token);
      checkUser({
        variables: {
          email: decryptEmaildata.DecryptEmail.email,
        },
      });
    }
  }, [decryptEmaildata]);

  useEffect(() => {
    if (decryptEmailError) {
      navigate('/auth/login');
    }
  }, [decryptEmailError]);

  useEffect(() => {
    if (checkUserData?.checkUserExistsInAuth0) {
      setShowModal({
        show: true,
        title: 'Account Already Exists',
        buttons: [
          {
            text: 'Login',
            handleSubmit: () => {
              navigate('/auth/login');
            },
          },
        ],
        bodyText: 'An account already exists for this email address',
      });
    }
  }, [checkUserData]);

  useEffect(() => {
    if (createAccountData?.createVerifiedAccount) {
      navigate('/auth/login');
    }
  }, [createAccountData]);
  useEffect(() => {
    if (createAccountError) {
      setShowModal({
        show: true,
        title: 'Error Setting Password',
        buttons: [],
        bodyText: 'An error has occured. Please try again later',
      });
    }
  }, [createAccountError]);
  if (!email || decryptEmailLoading || isLoading || willLogOut)
    return <Spinner />;
  return (
    <>
      <SEO title="Set password" />
      {(createAccountLoading || checkUserLoading) && <Spinner />}
      {showModal.show && (
        <ModalWithButtons
          title={showModal.title}
          disableToggling={true}
          bodyText={showModal.bodyText}
          isOpen={showModal.show}
          buttons={showModal.buttons}
        />
      )}
      <SetPassword
        email={email}
        createVerifiedAccount={createVerifiedAccount}
        salesforceToken={salesforceToken}
      />
    </>
  );
};

export default Password;
