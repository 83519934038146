export const generateRules = (password = '') => [
  {
    check: password && password.toLowerCase() !== password,
    text: 'Upper case letters (A-Z)',
  },
  {
    check: password && password.toUpperCase() !== password,
    text: 'Lower case letters (a-z)',
  },
  {
    check: /\d/.test(password),
    text: 'Numbers (0-9)',
  },
  {
    check: password && password.length > 7,
    text: 'Must be 8 characters minimum',
  },
];
