import * as React from 'react';

function Chevron(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="16"
      viewBox="0 0 11 16"
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#FFF" fillRule="nonzero">
          <g>
            <g>
              <path
                d="M15.836 7.63L8.366.16C8.26.054 8.137 0 7.998 0c-.139 0-.262.054-.369.16L.16 7.63c-.107.107-.16.23-.16.368 0 .14.054.262.16.369l.802.801c.106.107.23.16.368.16.14 0 .262-.053.369-.16l6.3-6.299 6.298 6.3c.107.106.23.16.369.16.14 0 .262-.054.369-.16l.801-.802c.107-.107.16-.23.16-.369 0-.139-.053-.262-.16-.368z"
                transform="translate(-784 -620) translate(647 620) matrix(0 -1 -1 0 147 16)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Chevron;
