import React, { useState, useRef, useEffect } from 'react';
import TextInput from 'components/form-elements/TextInput';
import PasswordLock from 'static/svg/PasswordLock';
import EyeIcon from 'static/svg/EyeIcon';
import './form-elements.scss';
import ReactTooltip from 'react-tooltip';

const TextInputWithLock = ({
  name,
  placeholder,
  tooltipData = '',
  validate = () => {},
}) => {
  const [lock, toggleLock] = useState(true);
  const pRef = useRef();
  useEffect(() => {
    ReactTooltip.rebuild();
  });
  return (
    <>
      <p
        ref={pRef}
        data-tip={tooltipData}
        data-for={`reacttooltip-${name}`}
      ></p>
      <div className="text-input-with-lock">
        <div>
          <PasswordLock />
        </div>
        <TextInput
          type={lock ? 'password' : ''}
          name={name}
          placeholder={placeholder}
          customClass="no-border"
          showErrorMessages={true}
          onFocus={() => {
            ReactTooltip.show(pRef.current);
          }}
          onBlur={() => {
            ReactTooltip.hide(pRef.current);
          }}
          onChange={value => {
            validate(value);
          }}
          autoComplete="new-password"
        />
        <div className="eye-icon" onClick={() => toggleLock(!lock)}>
          <EyeIcon />
        </div>
      </div>

      <ReactTooltip
        id={`reacttooltip-${name}`}
        html={true}
        offset={{ bottom: 10 }}
        effect="float"
        getContent={() => tooltipData}
        backgroundColor="black"
      />
    </>
  );
};

export default TextInputWithLock;
