import * as React from 'react';

const EyeIcon = props => (
  <svg
    width="20px"
    height="14px"
    viewBox="0 0 20 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    {' '}
    <title>show_password_inactive</title> <desc>Created with Sketch.</desc>{' '}
    <defs></defs>{' '}
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      {' '}
      <g id="show_password_inactive" fill-rule="nonzero" fill="#CCCCCC">
        {' '}
        <path
          d="M20,7.14646071 C20,7.32455593 19.8938749,7.50496407 19.7897906,7.62292329 C19.5795811,7.92129064 19.3693716,8.15952193 19.1060996,8.45788921 C17.8428021,9.88958993 16.4203169,11.1431954 14.8406847,12.0984335 C13.8406592,12.6951681 12.7875711,13.1739437 11.6814204,13.412175 C10.4691446,13.6504063 9.25890964,13.6504063 8.04663386,13.3520389 C5.93841681,12.8154403 4.04245004,11.5618349 2.35669277,10.0098621 C1.56687671,9.29285529 0.830123214,8.51802529 0.197454016,7.62292329 C-0.0658180053,7.26441986 -0.0658180053,6.7879573 0.197454016,6.42945387 C0.407663459,6.13108655 0.617872906,5.89285526 0.881144929,5.5944879 C2.14444246,4.16278721 3.56692773,2.90918177 5.14655985,1.95394368 C6.14658536,1.35720899 7.19967343,0.878433471 8.30582414,0.640202181 C9.5181,0.401970889 10.7283349,0.401970889 11.9406107,0.700338236 C14.0467869,1.23693688 15.9427536,2.49054231 17.6285109,4.04251511 C18.418327,4.75952191 19.1550805,5.53435183 19.7877496,6.42945387 C19.8938749,6.54972601 19.9979591,6.72782123 19.9979591,6.90591645 C20,7.02618859 20,7.02618859 20,7.08632462 C20,7.14646071 20,7.14646071 20,7.14646071 Z M9.99194607,2.29295242 C7.49894314,2.29295242 5.45200438,4.3398912 5.45200438,6.83289413 C5.45200438,9.325897 7.49894314,11.3728359 9.99194607,11.3728359 C12.484949,11.3728359 14.5318877,9.325897 14.5318877,6.83289413 C14.5318877,4.3398912 12.484949,2.29295242 9.99194607,2.29295242 Z M9.99194607,9.13755879 C8.71322886,9.13755879 7.68975943,8.11408943 7.68975943,6.83537224 C7.68975943,5.55665507 8.71322886,4.53318566 9.99194607,4.53318566 C11.2706633,4.53318566 12.2941326,5.55665507 12.2941326,6.83537224 C12.2941326,8.11408943 11.2706633,9.13755879 9.99194607,9.13755879 Z"
          id="Shape"
        ></path>{' '}
      </g>{' '}
    </g>
  </svg>
);

export default EyeIcon;
