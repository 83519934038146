import * as React from 'react';

function PasswordLock(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="20"
      viewBox="0 0 16 20"
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#7E7E7E" fillRule="nonzero" stroke="#7E7E7E" strokeWidth=".2">
          <g>
            <g>
              <path
                d="M12.667 6.704v-1.87C12.667 2.167 10.274 0 7.333 0 4.393 0 2 2.168 2 4.833v1.871C.866 6.9 0 7.888 0 9.077v6.514C0 16.919 1.081 18 2.41 18h9.847c1.329 0 2.41-1.081 2.41-2.41V9.076c0-1.188-.867-2.176-2-2.372zm-10-1.87C2.667 2.535 4.76.666 7.333.666 9.907.667 12 2.536 12 4.833v1.834H2.667V4.833zM14 15.59c0 .961-.782 1.743-1.743 1.743H2.41c-.961 0-1.743-.782-1.743-1.743V9.076c0-.96.782-1.743 1.743-1.743h9.847c.961 0 1.743.782 1.743 1.743v6.514z"
                transform="translate(-563 -499) translate(554 485) translate(10 15)"
              />
              <path
                d="M7.333 9.333C6.598 9.333 6 9.931 6 10.667v2C6 13.402 6.598 14 7.333 14c.736 0 1.334-.598 1.334-1.333v-2c0-.736-.598-1.334-1.334-1.334zM8 12.667c0 .367-.299.666-.667.666-.367 0-.666-.299-.666-.666v-2c0-.368.299-.667.666-.667.368 0 .667.299.667.667v2z"
                transform="translate(-563 -499) translate(554 485) translate(10 15)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default PasswordLock;
