import React, { useState } from 'react';
import styles from './setPassword.module.scss';
import Chevron from 'static/svg/Chevron';
import OneLineLogo from 'static/svg/OneLineLogo';
import { Formik, Form } from 'formik';
import TextInputWithLock from 'components/form-elements/TextInputWithLock';
import { SCHEMA, INITIAL_VALUES } from 'components/utils/setPassword';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import ReactDOMServer from 'react-dom/server';
import { generateRules } from './rules';
import CheckboxInput from 'components/form-elements/CheckboxInput';
import { Link } from 'gatsby';

const SetPassword = ({ email, createVerifiedAccount, salesforceToken }) => {
  const handleSubmit = async values => {
    await createVerifiedAccount({
      variables: {
        email: email,
        password: values.password,
        token: salesforceToken,
      },
    });
  };
  const getTooltipData = value => {
    const password = value ?? '';
    const rules = generateRules(password);
    if (rules.every(rule => rule.check)) return '';
    const getRow = (check, text) => {
      return (
        <p key={text} style={{ color: check ? 'green' : 'white' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <FontAwesomeIcon icon={check ? faCheckCircle : faCircle} />
          &nbsp; {text}
        </p>
      );
    };
    return ReactDOMServer.renderToString(
      <p>
        <p style={{ color: 'red' }}>
          <FontAwesomeIcon icon={faTimesCircle} />
          &nbsp; Should cointain:
        </p>
        {rules.map(rule => getRow(rule.check, rule.text))}
      </p>
    );
  };

  const checkIsInvalid = (isValid, isSubmitting, touched) =>
    !isValid || isSubmitting || Object.keys(touched).length === 0;
  const [tooltipData, setTooltipData] = useState(getTooltipData(''));
  return (
    <div className={styles.fullPage}>
      <Formik
        validateOnMount
        initialValues={INITIAL_VALUES}
        validationSchema={SCHEMA}
        // onSubmit={handleSubmit}
      >
        {({ isValid, isSubmitting, touched, values }) => {
          return (
            <div className={`${styles.modal} align-items-center`}>
              <div className={styles.header}>
                <OneLineLogo />
              </div>
              <div className={styles.inputArea}>
                <Form
                  className={styles.modelForm}
                  onKeyUp={e => {
                    if (e.key === 'Enter') {
                      if (!checkIsInvalid(isValid, isSubmitting, touched))
                        handleSubmit(values);
                    }
                  }}
                >
                  <div className={styles.modalText}>
                    <div>Create a Password for your account </div>
                    <div className={styles.subText}>{email}</div>
                  </div>
                  <div className={`${styles.modalField}`}>
                    <TextInputWithLock
                      name="password"
                      placeholder="Enter a new password*"
                      tooltipData={tooltipData}
                      validate={value => {
                        setTooltipData(getTooltipData(value));
                      }}
                    />
                  </div>
                  <div className={`${styles.modalField}`}>
                    <TextInputWithLock
                      name="confirmPassword"
                      placeholder="Confirm new password*"
                    />
                  </div>
                  <div className={`${styles.modalField} d-flex ml-1 mt-3 `}>
                    <CheckboxInput
                      name="privacyPolicyCheck"
                      smallSize={true}
                      handleSelect={() => {}}
                    />
                    <label
                      htmlFor="privacyPolicyCheck"
                      className={styles.privacyPolicyLabel}
                    >
                      By signing up, you agree to our{' '}
                      <Link
                        activeClassName="active"
                        to="https://www.goodingco.com/terms/"
                      >
                        Terms of Service{' '}
                      </Link>
                      and{' '}
                      <Link
                        activeClassName="active"
                        to="https://www.goodingco.com/privacy-policy/"
                      >
                        Privacy Policy
                      </Link>
                    </label>
                  </div>
                </Form>
              </div>
              <div
                className={`${styles.modalFooter} ${
                  checkIsInvalid(isValid, isSubmitting, touched)
                    ? styles.invalid
                    : ''
                }`}
              >
                <button
                  onClick={() => {
                    if (!checkIsInvalid(isValid, isSubmitting, touched))
                      handleSubmit(values);
                  }}
                  type="submit"
                >
                  Create password &nbsp;
                  <Chevron />
                </button>
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default SetPassword;
