import * as Yup from 'yup';

const INITIAL_VALUES = {
  password: '',
  confirmPassword: '',
  privacyPolicyCheck: false,
};

const getPasswordSchema = () => {
  return Yup.string()
    .required('Password is required.')
    .min(8, 'Password must be 8 characters minimum.')
    .matches(
      /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?!.*\s)[a-zA-Z\d]/,
      'Password is invalid'
    )
    .nullable();
};

const SCHEMA = Yup.object().shape({
  password: getPasswordSchema(),
  confirmPassword: getPasswordSchema().oneOf(
    [Yup.ref('password'), null],
    'Confirm password must match new passwords'
  ),
  privacyPolicyCheck: Yup.boolean().oneOf(
    [true],
    'Please Accept Terms of Service and Privacy Policy'
  ),
});

export { INITIAL_VALUES, SCHEMA };
